import React, { useState, useEffect } from "react";
import { get as _get } from "lodash";
import { Link, useLocation } from "react-router-dom";
import { Icon as IconLib } from '@iconify/react';
import { getConfigs } from '../../actions/uhoo/Config.actions'; // Singularity
import HeaderComponent from "./HeaderComponent.jsx";
import CircularProgress from '@mui/material/CircularProgress';
import Modal from "../../uhoo-components/Modal.jsx";
import Contact from "./components/contact.jsx"
import "./css/menu.css";

import Tooltip from '@mui/material/Tooltip'; // for Test

//Localization
import { useTranslation } from 'react-i18next';

import Icon from '../../uhoo-components/iconLibrary.jsx';

const MenuComponent = () => {

  const { t, i18n } = useTranslation();

  const [openModalContact, setOpenModalContact] = useState(false);

  const [path, updatePath] = useState("");
  const [loading, setLoading]  = useState(false);

  useEffect(() => {
    let currentPath = window.location.pathname;
    updatePath(currentPath);
  });

  const location = useLocation();

  /// ----- Singularity ----- ///

  const [report, setReport] = useState(false);
  const [isAura, setIsAura] = useState(false);
  const [wallet, setWallet]  = useState(false);

  const getStatusAdmin = () => {
    let status = sessionStorage.getItem("access_cms")
    if(status === null) {
      return getStatusAdmin()
    } else {
      return status;
    }
  }

  const Singularity = () => {
    useEffect(async () => {
      const _configs = await getConfigs();
      let config = _get(_configs, "data.data", []);

      setReport(
        _get(config.find((element) => {
          return element.name === "report.enabled";
        }), "value", false)
      );

      const URLactual = window.location.href;
      setIsAura(URLactual.includes("aura") || process.env.REACT_APP_DEFAULT_CONFIG_FB == "Aura");
      let _user = JSON.parse(sessionStorage.getItem("own"));
      let super_admin = JSON.parse(getStatusAdmin());
      let _community = _get(_user, "community", []);
      const organizationId = _get(_community, "uuid", 1);
      setWallet(super_admin); // only enable to cedej community
    }, []);
  };

  Singularity();

  /// --- end Singularity --- ///

  //renders

  if(loading){
    return (
      <div
        className="col-xs-12 col-md-12"
        style={{
          display: "flex", "justify-content": "center", background: "#dbdbdb", width: "100%", height: "90vh",
          "align-items": "center", cursor: "not-allowed", "pointer-events": "none", "border-radius": "16px"
        }}
      >
        <CircularProgress />
      </div>
    )
  }
  let URLactual = window.location;
  let pathname = URLactual.pathname
  
  return (
    <React.Fragment>
      <div>
        {pathname != '/' ?  <HeaderComponent setLoading={setLoading} /> : null}  
        <div className="menuContainer" role="menu">

          <div className="icoHome tooltip" role="button">
            <Link to="/" className="tooltip">
              <span className="tooltiptext">{t('uhoo.menu.home')}</span>
              {
                path === "/"
                  ? <Icon.Home style={{ color: Icon.active }}  className="menuIco"/>
                  : <Icon.Home style={{ color: Icon.inactive }} className="menuIco"/>
              }
            </Link>
          </div>

          <div className="ico2 tooltip  " role="button">
            <Link to="/community">
              <span className="tooltiptext">{t('uhoo.menu.community')}</span>
              {
                path === "/community"
                  ? <Icon.Community style={{ color: Icon.active }} className="menuIco"/>
                  : <Icon.Community style={{ color: Icon.inactive }} className="menuIco"/>
              }
            </Link>
          </div>

          <div className="tooltip ico3  " role="button">
            <Link to="/manage">
              <span className="tooltiptext">{t('uhoo.menu.content')}</span>
              {
                path === "/manage"
                  ? <Icon.Manage style={{ color: Icon.active }} className="menuIco"/>
                  : <Icon.Manage style={{ color: Icon.inactive }} className="menuIco"/>
              }
            </Link>
          </div>
          
          {
          isAura ?
          <>
            <div className="ico4 tooltip" role="button">
              <Link to="/donations">
                <span className="tooltiptext">{t('uhoo.home.otherCard.donations')}</span>
                {
                  path == "/donations"
                    // ? <Icon.WalletGray style={{ fill: Icon.active }} className="menuIco"/>
                    ? <IconLib icon="zondicons:wallet" style={{ color: Icon.active }} className="menuIco"/>
                    : <IconLib icon="zondicons:wallet" style={{ color: Icon.inactive }} className="menuIco"/>
                }
              </Link>
            </div>
            {
              wallet
              ? (
                <div className="ico4 tooltip" role="button">
                  <Link to="/donations">
                    <span className="tooltiptext">{t('uhoo.home.otherCard.donations')}</span>
                    {
                      path == "/donations"
                        // ? <Icon.WalletGray style={{ fill: Icon.active }} className="menuIco"/>
                        ? <IconLib icon="zondicons:wallet" style={{ color: Icon.active }} className="menuIco"/>
                        : <IconLib icon="zondicons:wallet" style={{ color: Icon.inactive }} className="menuIco"/>
                    }
                  </Link>
                </div>
              )
              : (
                <div className="ico4 tooltip menuDisabled">
                  <span className="tooltiptext menuDisabled">{t('uhoo.home.otherCard.donations')}</span>
                  <IconLib icon="zondicons:wallet" style={{ color: Icon.disabled }} className="menuIco"/>
                </div>
              )
            }
          </>
          :
          <>
            {report
              ? (
                <div className="ico4 tooltip  " role="button">
                  <Link to="/data">
                    <span className="tooltiptext">{t('uhoo.menu.data')}</span>
                    {
                      path === "/data"
                        ? <Icon.Data style={{ color: Icon.active }} className="menuIco"/>
                        : <Icon.Data style={{ color: Icon.inactive }} className="menuIco"/>
                    }
                  </Link>
                </div>
              )
              : (
                <div className="ico4 tooltip menuDisabled">
                  <span className="tooltiptext menuDisabled">{t('uhoo.menu.data')}</span>
                  <Icon.Data style={{ color: Icon.disabled }} className="menuIco"/>
                </div>
              )
            }
          </>
          }

          {/*<div className="icoMore" role="button">
            <span className="tooltiptext">Crear</span>          
            <Icon.Plus />
        </div>*/}

          <div className="icoHelp tooltip  " role="button" onClick={() => setOpenModalContact(true)}>
            <span className="tooltiptext">{t('uhoo.globalWords.help')}</span>
            <Icon.Help className="menuIco"/>
          </div>
        </div>
      </div>

      {openModalContact ? (
        <Modal>
          <Contact
            setOpenModal={setOpenModalContact}
          />
        </Modal>
      ) : (
        ""
      )}

    </React.Fragment>
  );
};

export default MenuComponent;




