import React, { useState, useEffect } from "react";
import { get as _get } from "lodash";
import { Link } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import HeaderComponent from "./HeaderComponent.jsx";

import { getConfigs } from '../../actions/uhoo/Config.actions'; // Singularity

import "./css/home.css";

import SystemNotification from "./SystemNotification.jsx";
import Icon from '../../uhoo-components/iconLibrary.jsx';
import { Icon as IconLib } from '@iconify/react';

//Localization
import { useTranslation } from 'react-i18next';

//Queries
import { getLandingPageSections } from "../../actions/aura";

const HomeComponent = (props) => {

  const { t, i18n } = useTranslation();

  /// ----- Singularity ----- ///

  const [report, setReport] = useState(false);
  const [massiveEmail, setMassiveEmail] = useState(false);
  const [webpage, setWebpage] = useState(true);
  const [widgets, setWidgets] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const [isAura, setIsAura] = useState(false);
  const [loading, setLoading]  = useState(false);
  const [wallet, setWallet]  = useState(false);
  const [createPost, setCreatePost] = useState(true);
  // const URLactual = window.location;
  // const isAura = URLactual.includes("aura");

  const getStatusAdmin = () => {
    let status = sessionStorage.getItem("access_cms")
    if(status === null) {
      return getStatusAdmin()
    } else {
      return status;
    }
  }

  //app.community.webTab.edition.enabled - widgets

  useEffect(async () => {
    let _user = JSON.parse(sessionStorage.getItem("own"));
    let _community = _get(_user, "community", []);
    const organizationId = _get(_community, "uuid", 1);
    let response = await getLandingPageSections(organizationId);
    sessionStorage.setItem("available_webpage", true);
    let super_admin = false;
    let access_cms = false;
    let create_post = false;
    let create_user = false;
    let delete_user = false;
    let edit_user = false;
    let create_channel = false;
    let delete_channel = false;
    let edit_channel = false;
    for (let index = 0; index <  _user.roles.length; index++) {
      let rol = _user.roles[index];
      if(rol.super == 1){
        super_admin = true;
      }
      if(rol.access_cms == 1){
        access_cms = true;
      }
      if(rol.create_post == 1){
        create_post = true;
      }
      if(rol.create_user == 1){
        create_user = true;
      }
      if(rol.delete_user == 1){
        delete_user = true;
      }
      if(rol.edit_user == 1){
        edit_user = true;
      }
      if(rol.create_channel == 1){
        create_channel = true;
      }
      if(rol.delete_channel == 1){
        delete_channel = true;
      }
      if(rol.edit_channel == 1){
        edit_channel = true;
      }
    }
    let not_found_webpage = response && response.data && response.data.description && response.data.description.includes("not found");
    if (not_found_webpage) {
      sessionStorage.setItem("available_webpage", false);
    }
    setWebpage(not_found_webpage == false && access_cms == true)
    // setCreatePost(create_post)
    setWallet(access_cms); // only enable to cedej community
    sessionStorage.setItem("super_admin", super_admin);
    sessionStorage.setItem("access_cms", access_cms);
    sessionStorage.setItem("create_post", create_post);
    sessionStorage.setItem("create_channel", create_channel);
    sessionStorage.setItem("edit_channel", edit_channel);
    sessionStorage.setItem("delete_channel", delete_channel);
    sessionStorage.setItem("create_user", create_user);
    sessionStorage.setItem("delete_user", delete_user);
    sessionStorage.setItem("edit_user", edit_user);
  }, []);

  const Singularity = () => {
    useEffect(async () => {
      const _configs = await getConfigs();
      let config = _get(_configs, "data.data", []);

      setReport(
        _get(config.find((element) => {
          return element.name === "report.enabled";
        }), "value", false)
      );

      setMassiveEmail(
        _get(config.find((element) => {
          return element.name === "massiveEmail.enabled";
        }), "value", false)
      );

      setNewsletter(
        _get(config.find((element) => {
          return element.name === "newsletter.enabled";
        }), "value", false)
      );

      setWidgets(
        _get(config.find((element) => {
          return element.name === "app.community.webTab.edition.enabled";
        }), "value", false)
      );
      const URLactual = window.location.href;
      setIsAura(URLactual.includes("aura") || process.env.REACT_APP_DEFAULT_CONFIG_FB == "Aura");
    }, []);
  };

  Singularity();

  /// --- end Singularity --- ///
  let URLactual = window.location;
  let pathname = URLactual.pathname

  return (
    <div>
    {pathname == '/' ? <HeaderComponent setLoading={setLoading} /> : null}  
    {
      loading ?
      <div
        className="col-xs-12 col-md-12"
        style={{
          display: "flex", "justify-content": "center", background: "#dbdbdb", width: "100%", height: "90vh",
          "align-items": "center", cursor: "not-allowed", "pointer-events": "none", "border-radius": "16px"
        }}
      >
        <CircularProgress />
      </div>
    :
      <div className="homeContent">
        <div className="homeHeadline">
          <div className="homeTitle">
            <h1>{t('uhoo.home.title')}</h1>
          </div>
          <div className="homeSubTitle">
            <h3>{t('uhoo.home.subTitle')}</h3>
          </div>
        </div>
        <section className="CardsContainer">
          

          <Link to="/createPost">
            <div className="homeCard createPublicationCard" role="button">
              <div className="postCardContent">
                <Icon.Publication className="icoCard" />
                <div className="cardCaption">
                  <h2 className="cardTitle">{t('uhoo.home.cards.publicationCard.cardTitle')}</h2>
                  <h6 className="cardSubTitle">{t('uhoo.home.cards.publicationCard.cardSubTitle')}</h6>
                </div>
              </div>
            </div>
          </Link>

          {massiveEmail
            ? (
              <Link to="/createMail">
                <div className="homeCard createEmailCard" role="button">
                  <div className="emailCardContent">
                    <Icon.Email className="icoCard" />
                    <div className="cardCaption">
                      <h2 className="cardTitle">{t('uhoo.home.cards.emailCard.cardTitle')}</h2>
                      <h6 className="cardSubTitle">{t('uhoo.home.cards.emailCard.cardSubTitle')}</h6>
                    </div>
                  </div>
                </div>
              </Link>
            )
            : (
              <Link to="/" style={{ order: 1 }}>
                <div className="homeCardDisabled createEmailCard">
                  <div className="emailCardContent">
                    <Icon.Email className="icoCard" />
                    <div className="cardCaption">
                      <h6 className="cardSubTitle">{t('uhoo.home.cards.emailCard.cardSubTitle')}</h6>
                      <h2 className="cardTitle">{t('uhoo.home.cards.emailCard.cardTitle')}</h2>
                    </div>
                  </div>
                </div>
              </Link>
            )
          }

          {webpage
            ? (
              <Link to="/manage/web">
                <div className="homeCard homeNewsletterCard" role="button">
                  <div className="newsletterCardContent">
                    <Icon.Newsletter className="icoCard" />
                    <div className="cardCaption">
                      <h2 className="cardTitle">{t('uhoo.manage.cards.webCard')}</h2>
                      <h6 className="cardSubTitle">{t('uhoo.home.cards.newsletterCard.cardSubTitle')}</h6>
                    </div>
                  </div>
                </div>
              </Link>
            )
            : (
              <Link to="/" style={{ order: 1 }}>
                <div className="homeCardDisabled homeNewsletterCard">
                  <div className="newsletterCardContent">
                    <Icon.Newsletter className="icoCard" />
                    <div className="cardCaption">
                      <h2 className="cardTitle">{t('uhoo.manage.cards.webCard')}</h2>
                      <h6 className="cardSubTitle">{t('uhoo.home.cards.newsletterCard.cardSubTitle')}</h6>
                    </div>
                  </div>
                </div>
              </Link>
            )
          }

          {widgets
            ? (
              <Link to="/createWidget">
                <div className="homeCard homeWidgetsCard" role="button">
                  <div className="widgetsCardContent">
                    <Icon.Widgets className="icoCard" />
                    <div className="cardCaption">
                      {isAura ? 
                      <h2 className="cardTitle">{t('basicWords.buttons.buttons')}</h2>
                      : 
                      <h2 className="cardTitle">{t('uhoo.home.cards.widgetsCard.cardTitle')}</h2>
                      }
                      <h6 className="cardSubTitle">{t('uhoo.home.cards.widgetsCard.cardSubTitle')}</h6>
                    </div>
                  </div>
                </div>
              </Link>
            )
            : (
              <Link to="/" style={{ order: 1 }}>
                <div className="homeCardDisabled homeWidgetsCard">
                  <div className="widgetsCardContent">
                    <Icon.Widgets className="icoCard" />
                    <div className="cardCaption">
                      <h2 className="cardTitle">{t('uhoo.home.cards.widgetsCard.cardTitle')}</h2>
                      <h6 className="cardSubTitle">{t('uhoo.home.cards.widgetsCard.cardSubTitle')}</h6>
                    </div>
                  </div>
                </div>
              </Link>
            )
          }

          <Link to="/notification">
            <div className="homeCard homeNotificationCard" role="button">
              <div className="notificationCardContent">
                <Icon.Notification className="icoCard" />
                <div className="cardCaption">
                  <h2 className="cardTitle">{t('uhoo.home.cards.notificationCard.cardTitle')}</h2>
                  <h6 className="cardSubTitle">{t('uhoo.home.cards.notificationCard.cardSubTitle')}</h6>
                </div>
              </div>
            </div>
          </Link>

        </section>
        <section className="OtherFunctions">
          <div className="OtherSubTitle">
            <h3>{t('uhoo.home.OtherSubTitle')}</h3>
          </div>

          <div className="OtherCardContainer">

            <Link to="/community">
              <div className="otherCard" role="button">
                <Icon.homeCommunity className="otherIcoCard" />
                <h2>{t('uhoo.home.otherCard.community')}</h2>
              </div>
            </Link>

            <hr className="v-line" />

            <Link to="/manage">
              <div className="otherCard" role="button">
                <Icon.homeManage className="otherIcoCard" />
                <h2>{t('uhoo.home.otherCard.manage')}</h2>
              </div>
            </Link>

            <hr className="v-line" />

            {
            isAura ?
            <>
              {
                wallet
                ? (
                  <Link to="/donations">
                    <div className="otherCard" role="button">
                      <IconLib icon="zondicons:wallet" className="otherIcoCard" />
                      <h2>{t('uhoo.home.otherCard.donations')}</h2>
                    </div>
                  </Link>
                )
                : (
                  <Link to="/">
                    <div className="otherCardDisabled">
                      <IconLib icon="zondicons:wallet" className="otherIcoCard" />
                      <h2>{t('uhoo.home.otherCard.donations')}</h2>
                    </div>
                  </Link>
                )
              }
            </>
          :
            <>
              {
                report
                ? (
                  <Link to="/data">
                    <div className="otherCard" role="button">
                      <Icon.homeData className="otherIcoCard" />
                      <h2>{t('uhoo.home.otherCard.data')}</h2>
                    </div>
                  </Link>
                )
                : (
                  <Link to="/">
                    <div className="otherCardDisabled">
                      <Icon.homeData className="otherIcoCard" />
                      <h2>{t('uhoo.home.otherCard.data')}</h2>
                    </div>
                  </Link>
                )
              }
            </>   
            }
            

          </div>
        </section>
        {/*
        <SystemNotification
          type = "Error"        
        />*/}
      </div>
    }
    
    </div>
  );
};

export default HomeComponent;